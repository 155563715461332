import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Feature from '../../models/Feature';
import i18n from '../../i18n';

function HomeViewAmenities(props) {
    const MIN_HEIGHT = 174;
    const MIN_ROW_AMENITIES = 4;
    const [showMore, setShowMore] = useState(false);
    const [refHeight, setRefHeight] = useState(MIN_HEIGHT);
    const { features } = props;
    const ref = useRef(null);

    useEffect(() => {
        setRefHeight(ref?.current?.clientHeight);
    }, [ref]);

    const featuresGroups = () => {
        const featured = [];
        Feature.FEATURES_GROUPS.filter(
            (item) =>
                item.type !== Feature.AMENITIES_TYPE_RULE_FAVOR && item.type !== Feature.SURROUNDING_TYPE_TAGS
        ).forEach((item) => {
            let value = 0;
            const newItem = Object.assign(
                {},
                {
                    ...item,
                    title: item.titleView ? item.titleView : item.title,
                    labelBit: Object.entries(features)
                        .map((key) => {
                            if (item.label.includes(key[0])) {
                                value += key[1];
                                return value;
                            } else {
                                return (value += 0);
                            }
                        })
                        .slice(-1)
                        .pop()
                }
            );
            return featured.push({ ...newItem });
        });
        return featured;
    };

    const getMinHeight = () => {
        const featured = [];
        Feature.FEATURES_GROUPS.filter(
            (item) =>
                item.type !== Feature.AMENITIES_TYPE_RULE_FAVOR && item.type !== Feature.SURROUNDING_TYPE_TAGS
        ).forEach((item) => {
            const newItem = Object.assign(
                {},
                {
                    ...item,
                    numberAmenities: Feature.FEATURES.map((feature) => {
                        if (item.features.includes(feature.category)) {
                            const category = Feature.AMENITIES_LABEL[feature.category];
                            if (features[category] & feature.value) {
                                return 1;
                            }
                        }
                        return 0;
                    }).reduce((a, b) => a + b)
                }
            );
            return featured.push({ ...newItem });
        });
        const found = featured.find((feature) => feature.numberAmenities > 0);
        return found?.numberAmenities > MIN_ROW_AMENITIES ? MIN_HEIGHT : MIN_HEIGHT / 2;
    };

    return (
        <>
            <div
                className="readmore-amenities"
                style={{
                    maxHeight: showMore ? refHeight : getMinHeight()
                }}
            >
                <div ref={ref}>
                    {featuresGroups().map(
                        (item) =>
                            item.labelBit > 0 && (
                                <div key={item.title} className="category-amenity">
                                    {item.labelBit > 0 && <h3>{i18n.t(item.title)}</h3>}
                                    <div className="row container-amenities">
                                        {Feature.FEATURES.map((feature) => {
                                            if (item.features.includes(feature.category)) {
                                                const category = Feature.AMENITIES_LABEL[feature.category];
                                                const oldCategory =
                                                    Feature.AMENITIES_LABEL[feature.old_category];
                                                return (
                                                    (Boolean(features[oldCategory] & feature.old_value) ||
                                                        Boolean(features[category] & feature.value)) && (
                                                        <div
                                                            className="col-xs-6 col-sm-4 col-md-3 feature"
                                                            itemProp="amenityFeature"
                                                            itemScope
                                                            itemType="https://schema.org/LocationFeatureSpecification"
                                                            key={features.icon}
                                                        >
                                                            <i className={`icon icon-${feature.icon}`} />
                                                            <span itemProp="name">
                                                                {i18n.t(Feature.getFeatureLabel(feature))}
                                                            </span>
                                                            <meta itemProp="value" />
                                                        </div>
                                                    )
                                                );
                                            }
                                            return null;
                                        })}
                                    </div>
                                </div>
                            )
                    )}
                </div>
            </div>
            <a className="see-more" onClick={() => setShowMore(!showMore)}>
                {i18n.t(`common:${showMore ? 'see_less' : 'see_more'}`)}
            </a>
        </>
    );
}

HomeViewAmenities.propTypes = {
    features: PropTypes.object
};

export default HomeViewAmenities;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { Button, AlertDialog, SelectField, Textarea } from '@homeexchange/design';
import i18n from '../../../i18n';
import { connect } from 'react-redux';
import Utils from '../../../api/Utils';
import ErrorDialog from '../../messaging/ErrorDialog';
var reasons = [
    { value: 0, label: 'global:modale.reportAbuse.reason.inappropriatePicture' },
    { value: 1, label: 'global:modale.reportAbuse.reason.inappropriateText' },
    { value: 2, label: 'home:modale.reportAbuse.reason.homeMislocated' },
    { value: 3, label: 'global:modale.reportAbuse.reason.hatefulContent' },
    { value: 99, label: 'global:modale.reportAbuse.reason.other' }
];
var ReportHomeAbuse = function (_a) {
    var userId = _a.userId, homeId = _a.homeId, user = _a.user;
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var _c = useState(''), description = _c[0], setDescription = _c[1];
    var _d = useState(null), selectedReason = _d[0], setSelectedReason = _d[1];
    var _e = useState(false), showReportError = _e[0], setShowReportError = _e[1];
    var _f = useState(false), showReasonError = _f[0], setShowReasonError = _f[1];
    var _g = useState(false), showDescriptionError = _g[0], setShowDescriptionError = _g[1];
    var _h = useState(false), isLoading = _h[0], setIsLoading = _h[1];
    var closeModal = function () {
        setIsOpen(false);
        setSelectedReason(null);
        setDescription('');
        setIsLoading(false);
    };
    var handleReasonChange = function (value) {
        setSelectedReason(value);
        setShowReasonError(false);
    };
    var handleDescriptionChange = function (event) {
        setDescription(event.target.value);
        setShowDescriptionError(false);
    };
    var handleReportAbuse = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (description.length === 0) {
                        setShowDescriptionError(true);
                    }
                    if (selectedReason === null) {
                        setShowReasonError(true);
                    }
                    if (!(description.length > 0 && selectedReason !== null)) return [3 /*break*/, 5];
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, 4, 5]);
                    setIsLoading(true);
                    return [4 /*yield*/, Utils.contact({
                            email: user === null || user === void 0 ? void 0 : user.get('email'),
                            locale: i18n.language,
                            subject: "Abuse: ".concat((_a = reasons.find(function (reason) { return reason.value === selectedReason; })) === null || _a === void 0 ? void 0 : _a.label),
                            message: description,
                            status: 'new',
                            priority: 'high',
                            tags: ['abuse', 'message'],
                            extra_informations: ["user ".concat(userId), "home ".concat(homeId), "email ".concat(user === null || user === void 0 ? void 0 : user.get('email'))]
                        })];
                case 2:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _b.sent();
                    console.error(error_1);
                    setShowReportError(true);
                    return [3 /*break*/, 5];
                case 4:
                    setIsOpen(false);
                    setSelectedReason(null);
                    setDescription('');
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", null,
        React.createElement(Button, { onClick: function () { return setIsOpen(true); }, size: "small", color: "ghost", isFullWidth: true }, "".concat(i18n.t('global:page.reportAbuse.cta'))),
        showReportError && (React.createElement(ErrorDialog, { title: i18n.t('messaging:report_abuse'), isOpen: showReportError, confirmActionText: 'OK', onConfirmAction: function () {
                setShowReportError(false);
            } }, i18n.t('common:error-retry'))),
        isOpen && (React.createElement(AlertDialog, { title: i18n.t('home:modale.reportAbuse.title', {
                HostName: user === null || user === void 0 ? void 0 : user.get('first_name')
            }), isOpen: isOpen, confirmActionText: i18n.t('common:continue'), onConfirmAction: handleReportAbuse, onDismissAction: closeModal, dismissActionText: i18n.t('common:cancel'), confirmActionIsLoading: isLoading },
            React.createElement(SelectField, { ariaLabel: i18n.t('global:modale.reportAbuse.reasons.label'), label: i18n.t('global:modale.reportAbuse.reasons.label'), suggestions: reasons.map(function (reason) { return ({
                    value: i18n.t(reason.label),
                    id: reason.value.toString()
                }); }), onChange: handleReasonChange, value: selectedReason, hasError: showReasonError }),
            React.createElement(Textarea, { id: "report-home-abuse-description", title: i18n.t('global:modale.reportAbuse.message.label'), message: i18n.t('global:modale.reportAbuse.message.helper'), onChange: handleDescriptionChange, value: description, status: showDescriptionError ? 'error' : undefined })))));
};
var mapStateToProps = function (state, ownProps) { return ({
    user: state.loadedUsers[ownProps.userId]
}); };
export default connect(mapStateToProps)(ReportHomeAbuse);

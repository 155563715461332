import PropTypes from 'prop-types';
import React from 'react';
import { Button as BootstrapButton, Modal as BootstrapModal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toggleFavorite } from '../../actions/homeFavorites';
import { updateHome } from '../../actions/homes';
import Api from '../../api/Api';
import i18n from '../../i18n';
import Availability from '../../models/Availability';
import HomeModel from '../../models/Home';
import UserModel from '../../models/User';
import Analytics from '../../utils/analytics';
import Utils from '../../utils/utils';
import {
    BUTTON_BLOCKER_EVENT,
    HOME_VIEW_EVENT,
    MODAL_BLOCKER_EVENT,
    trackButtonClick,
    trackHomeview
} from '../../utils/utils-ts';
import Loading from '../common/Loading';
import Contact from '../popup/Contact/Contact';
import VerificationModal from '../popup/VerificationModal/VerificationModal';
import { HomeBlocker } from './contact-ts/home-blocker';
import { Button } from '@homeexchange/design';

export class ContactButton extends React.Component {
    static propTypes = {
        accessToken: PropTypes.bool,
        homeId: PropTypes.number.isRequired,
        user: PropTypes.instanceOf(UserModel),
        home: PropTypes.instanceOf(HomeModel),
        updateHome: PropTypes.func.isRequired,
        userHomes: PropTypes.arrayOf(PropTypes.instanceOf(HomeModel)),
        availabilities: PropTypes.arrayOf(PropTypes.instanceOf(Availability)),
        homeFavorites: PropTypes.array,
        action: PropTypes.shape({
            toggleFavorite: PropTypes.func
        })
    };

    static defaultProps = {
        userHomes: [],
        availabilities: []
    };

    constructor(props) {
        super(props);

        this.state = {
            home: null,
            alreadyContacted: false,
            conversationInProgress: false,
            showModal: false
        };

        this.handleShowModal = this.handleShowModal.bind(this);
        this.handleHideModal = this.handleHideModal.bind(this);
        this.handleShowEllipsisModal = this.handleShowEllipsisModal.bind(this);
        this.handleHideEllipsisModal = this.handleHideEllipsisModal.bind(this);
        this.handleCollectionLinkClick = this.handleCollectionLinkClick.bind(this);
    }

    componentDidMount() {
        $.when(
            // find previous conversation
            Api.Conversation.me({ 'e.home': this.props.homeId }, 0, 1),
            // find previous conversation in progress
            Api.Conversation.me({ 'e.home': this.props.homeId, 'e.status': [0, 1, 2] }, 0, 1, {
                'c.createdAt': 'DESC'
            })
        ).done((prevConv, prevConvInProgress) => {
            this.setState({
                alreadyContacted: prevConv[0].conversations.length > 0,
                conversationInProgress: _.first(prevConvInProgress[0].conversations)
            });
        });
    }

    isHomeCollectionOnly() {
        // user not collection and the home is collection
        const { home, user } = this.props;

        return (
            home?.get('is_he_collection') &&
            home?.get('is_search_he_collection_only') &&
            !user?.get('is_he_collection')
        );
    }

    isDisabled() {
        // user should accept contact on unavailable periods
        // or have at least one available period define in his calendar
        return (
            this.props.home &&
            !(
                this.props.home.get('contact_allowed') === HomeModel.CONTACT_ALLOWED_ON_UNAVAILABLE ||
                this.props.availabilities.filter(this.availablePeriodsFilter).length > 0
            )
        );
    }

    availablePeriodsFilter(availability) {
        return (
            availability.get('type') === Availability.AVAILABLE.type ||
            availability.get('type') === Availability.NON_RECIPROCAL.type ||
            availability.get('type') === Availability.RECIPROCAL.type
        );
    }
    isMobile() {
        return 'matchMedia' in window && window.matchMedia('(max-width: 767px)').matches;
    }

    renderFavorite() {
        return (
            <div className="m-t-15">
                <Button
                    color="tertiary"
                    onClick={() => this.handleFavorite(this.props.accessToken)}
                    size="small"
                    isFullWidth
                    icon={this.props.home.isFavorite(this.props.homeFavorites) ? 'heart-fill' : 'heart'}
                >
                    {i18n.t(
                        `home:${
                            this.props.home.isFavorite(this.props.homeFavorites)
                                ? 'remove_favorites'
                                : 'add_favorites'
                        }`
                    )}
                </Button>
            </div>
        );
    }
    handleCollectionLinkClick(e) {
        e.preventDefault();

        const eventData = {
            area: 'homeview',
            action: 'click',
            text: 'blocker_contact_collection',
            collection: true
        };
        Analytics.trackGTM('button_blocker', { event_data: eventData });

        window.open('/collection/subscription/payment', '_blank');
    }

    render() {
        const { user, home } = this.props;
        const { conversationInProgress, alreadyContacted } = this.state;

        if (home && user) {
            // I can't contact myself
            if (home?.get('user').id == user?.id) {
                return false;
            } else if (conversationInProgress) {
                return (
                    <div className={`contact-button ${this.isMobile() && 'd-flex'}`}>
                        <Button
                            color="primary"
                            href={i18n.t('exchange:conversation.url', {
                                id: conversationInProgress.id
                            })}
                            size="small"
                            isFullWidth
                        >
                            {i18n.t('home:continue_conversation')}
                        </Button>
                        {!this.isMobile() && (
                            <Button
                                color="white"
                                size="small"
                                isFullWidth
                                onClick={this.handleShowModal}
                                disabled={this.isDisabled() || this.isHomeCollectionOnly()}
                                className="m-t-15"
                            >
                                {i18n.t('home:new_request')}
                            </Button>
                        )}
                        {this.renderAlert()}
                        {!this.isMobile() && this.renderFavorite()}
                        {this.renderModal()}
                        {this.renderEllipsisModal()}
                        {this.isMobile() && (
                            <button
                                type="button"
                                className="btn btn-white btn-dots"
                                onClick={this.handleShowEllipsisModal}
                            >
                                <i className="icon-vertical-dots" />
                            </button>
                        )}
                    </div>
                );
            } else if (alreadyContacted) {
                return (
                    <div className="contact-button">
                        <Button
                            color="primary"
                            size="small"
                            isFullWidth
                            onClick={this.handleShowModal}
                            disabled={this.isDisabled() || this.isHomeCollectionOnly()}
                        >
                            {i18n.t('home:already_contacted')}
                        </Button>
                        {this.renderAlert()}
                        {this.renderFavorite()}
                        {this.renderModal()}
                    </div>
                );
            } else if (
                home?.get('is_he_collection') &&
                home?.get('is_search_he_collection_only') &&
                !user?.isCollection() &&
                user?.isUserEligibleCollection()
            ) {
                return (
                    <div className="contact-button">
                        <Button color="primary" size="small" isFullWidth disabled={true}>
                            {i18n.t('home:contact')}
                        </Button>
                        {this.renderAlert()}
                        {this.renderFavorite()}
                        {this.renderModal()}
                    </div>
                );
            } else {
                return (
                    <div className="contact-button">
                        <Button
                            color="primary"
                            size="small"
                            isFullWidth
                            onClick={this.handleShowModal}
                            disabled={this.isDisabled() || this.isHomeCollectionOnly()}
                        >
                            {i18n.t('home:contact')}
                        </Button>
                        {this.renderAlert()}
                        {this.renderFavorite()}
                        {this.renderModal()}
                    </div>
                );
            }
        } else {
            return <Loading />;
        }
    }

    renderAlert() {
        const { home, user } = this.props;

        if (this.isDisabled()) {
            return <p className="alert alert-warning">{i18n.t('home:calendar.only_available_periods')}</p>;
        }
        if (
            home.get('user').get('verified_only') &&
            user.get('verified_status') < UserModel.STATUS_VERIFIED
        ) {
            return <p className="alert alert-success">{i18n.t('home:only_contact_by_verified')}</p>;
        }
        if (
            home?.get('is_he_collection') &&
            home?.get('is_search_he_collection_only') &&
            !user?.isCollection() &&
            user?.isUserEligibleCollection()
        ) {
            return (
                <p className="alert collection grey-alert">
                    {i18n.t('home:contact.eligibleCollection')}
                    <p>
                        <a className="collection-link" href="#" onClick={this.handleCollectionLinkClick}>
                            {i18n.t('home:contact.eligibleCollectionCTA')}
                        </a>
                    </p>
                    <img src={`${Utils.getCloudfrontDomain()}/images/website/collection/logo.svg`} />
                </p>
            );
        }
        if (this.isHomeCollectionOnly()) {
            return (
                <p className="alert alert-success">
                    {i18n.t('collection:error.msg.cant_contact_collection_member')}
                </p>
            );
        }
        return false;
    }

    handleShowModal() {
        trackButtonClick(HOME_VIEW_EVENT, 'contact_home_view');
        const userHome = _.first(Api.Home.sortByStatusAndCompletionRate(this.props.userHomes));
        if (!userHome || userHome?.get('completion_rate') < HomeModel.MINIMUM_FILLING_TO_CONTACT) {
            trackHomeview(MODAL_BLOCKER_EVENT, 'contact_modal_blocker');
        }
        if (this.isMobile()) {
            this.setState({
                showEllipsisModal: false
            });
        }
        this.setState({ showModal: true });
    }
    handleShowEllipsisModal() {
        this.setState({
            showEllipsisModal: true
        });
    }

    handleFavorite(accessToken) {
        if (accessToken) {
            Analytics.trackGTM('MixpanelAddFavorites', { userStatus: this.props.user.getStatus() });

            const eventHandleFavorites = {
                action: 'click',
                text: 'cta_home_view',
                area: 'home view'
            };
            Analytics.trackGTM('HandleFavorites', { event_data: eventHandleFavorites });
            this.props.action.toggleFavorite(this.props.homeId, this.props.homeFavorites);
        }
    }

    handleHideModal() {
        this.setState({ showModal: false });
    }
    handleHideEllipsisModal() {
        this.setState({ showEllipsisModal: false });
    }

    renderModal() {
        const { userHomes } = this.props;

        if (!userHomes || userHomes.length === 0) {
            return (
                <HomeBlocker
                    title={`${i18n.t('exchange:contact.cant_contact')}`}
                    message={`${i18n.t('exchange:contact.no_home_text')}`}
                    confirmAction={() => {
                        trackHomeview(BUTTON_BLOCKER_EVENT, 'contact_create_home', null);
                        window.location.href = i18n.t('home:home.create.url');
                    }}
                    confirmText={i18n.t('home:create_a_home')}
                    isOpen={this.state.showModal}
                    setIsOpen={this.handleHideModal}
                />
            );
        }

        const myHomes = Api.Home.sortByStatusAndCompletionRate(userHomes);
        const myHome = myHomes[0];

        if (myHome.get('completion_rate') < HomeModel.MINIMUM_FILLING_TO_CONTACT) {
            return (
                <HomeBlocker
                    title={`${i18n.t('exchange:contact.cant_contact')}`}
                    message={`${i18n.t('exchange:contact.home_filling_text')}`}
                    confirmAction={() => {
                        trackHomeview(BUTTON_BLOCKER_EVENT, 'contact_complete_home', null);
                        window.location.href = myHome.getEditUrl();
                    }}
                    confirmText={i18n.t('home:filling_my_home')}
                    isOpen={this.state.showModal}
                    setIsOpen={this.handleHideModal}
                />
            );
        }

        if (myHome.get('status') != HomeModel.STATUS_ONLINE) {
            return (
                <HomeBlocker
                    title={`${i18n.t('exchange:contact.cant_contact')}`}
                    message={`${i18n.t('exchange:contact.home_offline_text')}`}
                    confirmAction={() => {
                        trackHomeview(BUTTON_BLOCKER_EVENT, 'contact_home_offline', null);
                        window.location.href = myHome.getEditUrl();
                    }}
                    confirmText={i18n.t('home:publish_my_home')}
                    isOpen={this.state.showModal}
                    setIsOpen={this.handleHideModal}
                />
            );
        }

        return this.renderLegacyModal();
    }

    renderLegacyModal() {
        const { user } = this.props;
        if (!user.canCollectionMemberContact()) {
            return (
                <VerificationModal
                    show={this.state.showModal}
                    closeModal={this.handleHideModal}
                    homes={this.state.verificationHomes}
                />
            );
        }

        return (
            <BootstrapModal
                className="modal-contact"
                bsSize="large"
                show={this.state.showModal}
                onHide={this.handleHideModal}
                id={`modalSendMessage-${this.props.home.id}`}
                aria-labelledby="modalSendMessageLabel"
            >
                <Contact
                    user={this.props.user}
                    home={this.props.home}
                    userHomes={this.props.userHomes}
                    availabilities={this.props.availabilities}
                    updateHome={this.props.updateHome}
                />
                <BootstrapButton
                    aria-label="Close"
                    className="modal-close"
                    data-dismiss="modal"
                    onClick={this.handleHideModal}
                >
                    <span aria-hidden="true" className="icon-circle-close"></span>
                </BootstrapButton>
            </BootstrapModal>
        );
    }

    renderEllipsisModal() {
        const { user } = this.props;

        if (!user.canCollectionMemberContact()) {
            return (
                <VerificationModal
                    show={this.state.showModal}
                    closeModal={this.handleHideModal}
                    homes={this.state.verificationHomes}
                />
            );
        }

        return (
            <BootstrapModal
                className="modal-contact modal-mobile"
                bsSize="large"
                show={this.state.showEllipsisModal}
                onHide={this.handleHideEllipsisModal}
                aria-labelledby="modalSendMessageLabel"
            >
                <div className="button-container">
                    <button
                        type="button"
                        className="btn btn-block btn-white"
                        onClick={this.handleShowModal}
                        disabled={this.isDisabled() || this.isHomeCollectionOnly()}
                    >
                        {i18n.t('home:new_request')}
                    </button>
                    {this.renderFavorite()}
                </div>
                <BootstrapButton
                    aria-label="Close"
                    className="modal-close"
                    data-dismiss="modal"
                    onClick={this.handleHideEllipsisModal}
                >
                    <span aria-hidden="true" className="icon-remove"></span>
                </BootstrapButton>
            </BootstrapModal>
        );
    }
}

function getHomeFromState(state, ownProps) {
    if (state.loadedHomes && ownProps.homeId && state.loadedHomes[ownProps.homeId]) {
        return state.loadedHomes[ownProps.homeId];
    }
    return null;
}

const mapStateToProps = (state, ownProps) => ({
    userHomes: state.homes,
    availabilities: state.availabilities[ownProps.homeId],
    accessToken: Boolean(state.auth.accessToken),
    user: state.user,
    home: getHomeFromState(state, ownProps),
    homeFavorites: state.homeFavorites
});

const mapDispatchToProps = (dispatch) => ({
    action: bindActionCreators({ toggleFavorite }, dispatch),
    updateHome: bindActionCreators({ updateHome }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactButton);

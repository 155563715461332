import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Feature from '../../models/Feature';
import i18n from '../../i18n';

function HomeViewSection({ features, featureType, hasFeature, title, classNameContainer, classNameChild }) {
    return (
        hasFeature &&
        Feature.FEATURES_GROUPS.filter((item) => item.type === featureType).map((item) => (
            <Fragment key={item.type}>
                <h2>{i18n.t(title ? title : item.title)}</h2>
                <div className={classNameContainer}>
                    {Feature.FEATURES.map((feature) => {
                        if (item.features.includes(feature.category)) {
                            const category = Feature.AMENITIES_LABEL[feature.category];
                            return (
                                Boolean(features[category] & feature.value) && (
                                    <div className={classNameChild} key={features.icon}>
                                        <i className={`icon icon-${feature.icon}`} />
                                        <span>{i18n.t(Feature.getFeatureLabel(feature))}</span>
                                    </div>
                                )
                            );
                        }
                        return null;
                    })}
                </div>
            </Fragment>
        ))
    );
}

HomeViewSection.propTypes = {
    features: PropTypes.object,
    featureType: PropTypes.string,
    hasFeature: PropTypes.bool,
    title: PropTypes.string,
    classNameContainer: PropTypes.string,
    classNameChild: PropTypes.string
};

export default HomeViewSection;
